input{
    border-radius: 0 !important;
    background-color: #dcdcdc !important;
    color: #222222 !important;
}
form{
}
.myLabel{
    background-color: #F5871F;
    color: #222222;
    margin-bottom: 0;
    width: 100%;
}
.intro{
    background-color: #dcdcdc;
    color: #222222 !important;
}
.leftSection{

}
.rightSection{

}
